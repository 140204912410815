import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SobrePage = () => (
  <Layout>
    <SEO title="Sobre" />
    <section className="intro">
      <div className="intro-container">
        <h2>Sobre</h2>
        <p>Fundado em 2019, o Mr. Rubber Brasil é o primeiro evento rubber na América do Sul e o segundo concurso rubber na América Latina. A segunda edição do evento acontecerá de 23 a 25 de setembro de 2022 em São Paulo, e a segunda edição do concurso acontecerá no dia 24 de setembro de 2022 na Upgrade Club.</p>
      </div>
    </section>
    <section className="content">
      <div className="content-container">
        <p>Mais informações chegando em breve.</p>
        <p>Fundadores:</p>
        <ul>
          <li><strong>Mestre Guto Lemos</strong> (ele/dele), Personalidade Leather 2018 e Personalidade Rubber 2019, Agita Planeta</li>
          <li><strong>Marc Vini</strong> (ele/dele)</li>
          <li><a href="https://mirubberxx.com" rel="noreferrer noopener" target="_blank"><strong>Preston "wexx"</strong></a> (ele/elu), Mr. International Rubber 20 e Mr. New England Rubber 2016</li>
        </ul>
        <p><Link to="/" className="call-to-action">Voltar à página inicial</Link></p>
      </div>
    </section>
  </Layout>
)

export default SobrePage
